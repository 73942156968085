import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Header, Footer } from "../components"
import { AppProvider } from "../context/useAppContext"
import "./_layout.scss"
import Script from "@gumgum/react-script-tag"
import { useGlobalConfig } from "../hooks"
import BackToTop from "../components/BackToTop/backToTop.component"
import { Helmet } from "react-helmet"

const Layout = ({ children, handleLocation, whatsapp }) => {
  let notificationCount = 0
  let minimizedButton 
  let chatContainer
  const config = useGlobalConfig()
  const scripts = config?.allStrapiGlobalConfig?.nodes.map(item =>
    item.Script.map(script =>
      script.name.toLowerCase() === "analytics" && script.enable === true ? (
        <Helmet>
          <script async defer src={script.src}></script>
          <script async defer>
            {`window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${script.src.replace(
                      "https://www.googletagmanager.com/gtag/js?id=",
                      ""
                    )}');`}
          </script>
        </Helmet>
      ) : script.name.toLowerCase() === "aivo" && script.enable === true ? (
        <Helmet>
          <script async defer src={script.src}></script>
          <script async defer>
            {` if (typeof window.$aivo !== "undefined") {
              window.$aivo.ready(() => {
              window.$aivo.registerTokenForExternalZendesk('de93c510aaac1a9a8a8529970dad4193')
            })}`}
          </script>
        </Helmet>
      ) : script.enable === true ? (
        <Script
          key={script.name}
          type="text/javascript"
          src={script.src}
          id={script.name}
          defer
          isHydrating={true}
        />
      ) : null
    )
  )

  const codeFaceMeta =
    config?.allStrapiGlobalConfig?.nodes[0]?.facebookMeta ||
    "9qa1ov9qrr8994vi9355sv15rd4sv6"

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isActive, setIsActive] = useState(false)

  const handleScroll = value => {
    setIsActive(value)
  }

  const classActive = isActive ? "layout-off" : "layout-on"

  
  function handleMutations(mutations) {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (isFromOtherUsers(node)) {
          if (chatContainer && chatContainer.classList.contains("minimize")) {
            handleNewMessage();
        }
        }
      });
    });
  }

  function isFromOtherUsers(node) {
    return node.nodeType === Node.ELEMENT_NODE && node.matches("li.fromOtherUsers");
  }

  function handleNewMessage() {
    playNotificationSound();
    updateNotificationBadge();
  }

  function playNotificationSound() {
    const audio = new Audio("msj-notification.mp3") // Ruta del archivo de audio\
    audio.play()
  }

  function resetNotificationBadge() {
    const minimizedButton = document.querySelector(".minimized")
    const badge = minimizedButton.querySelector(".notification-badge")
    if (badge) {
      badge.textContent = "0"
      badge.style.display = "none"
    }
    notificationCount = 0
  }

  function setupNotificationBadgeListeners(chatContainer) {
    if (minimizedButton) {
      minimizedButton.addEventListener("click", resetNotificationBadge);
      chatContainer.addEventListener("click", resetNotificationBadge);
    } else {
      console.error("Botón minimizado no encontrado.");
    }
  }

  function updateNotificationBadge() {
    let badge = minimizedButton.querySelector(".notification-badge");
    if (!badge) {
      badge = document.createElement("span");
      badge.className = "notification-badge";
      minimizedButton.appendChild(badge);
    }
    notificationCount++;
    badge.textContent = notificationCount;
    badge.style.display = notificationCount > 0 ? "flex" : "none";
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition = function () {}
      navigator.geolocation.watchPosition = function () {}
      navigator.geolocation.clearWatch = function () {}
    }

    const link = document.createElement("link")
    link.rel = "stylesheet"
    link.href = "https://bots.kore.ai/webclient/UI/dist/kore-ai-sdk.min.css"
    document.body.appendChild(link)

    const style = document.createElement("style")
    style.innerHTML = `
      .minimized {
        position: fixed!important;
        bottom: 100px!important;
        left: 45px!important;
        width: 50px!important;
        height: 50px!important;
        cursor: pointer!important;
        border-radius: 50%!important;
        background-color: #fff!important;
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)!important;
        transition: transform 0.3s ease!important;
      }
      .kore-chat-window .messages {
        background-image: url(https://hubspot-public-content.s3.sa-east-1.amazonaws.com/KoreIa-CMS/message.png)!important;
      }
      .kore-chat-window.minimize .minimized-title {
        position: absolute;
      }
      .minimized-title{
        position: fixed !important;
        left: 100px !important;
        padding: 8px 20px!important;
        background: #FFF!important;
        color: #000!important;
        bottom: 120px !important;
      }
      .kore-chat-window {
        position: fixed;
        left: 0 !important; 
        right: auto !important;
      }
      .kore-chat-window.minimize {
        left: 92px !important;
        right: auto!important;
        bottom: 10px!important;
      }
      .kore-chat-window.kore-chat-window.expanded{
        right: auto!important;
      }
      .expand-btn img,
      .reload-btn img,
      .minimize-btn img,
      .close-btn img {
        display: none!important;
      }
      .expand-btn {
        background-image: url('https://hubspot-public-content.s3.sa-east-1.amazonaws.com/KoreIa-CMS/expand-btn.png')!important;
        background-size: contain!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
        margin-right: 5px !important;
        padding: 5px !important;
      }
      .reload-btn {
        background-image: url('https://hubspot-public-content.s3.sa-east-1.amazonaws.com/KoreIa-CMS/reload-btn.png')!important;
        background-size: contain!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
        margin-right: 5px !important;
        padding: 5px !important;
      }
      .minimize-btn {
        background-image: url('https://hubspot-public-content.s3.sa-east-1.amazonaws.com/KoreIa-CMS/minimize-btn.png')!important;
        background-size: contain!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
        margin-right: 5px !important;
        padding: 5px !important;
      }
      .close-btn {
        background-image: url('https://hubspot-public-content.s3.sa-east-1.amazonaws.com/KoreIa-CMS/close-btn.png')!important;
        background-size: contain!important;
        background-repeat: no-repeat!important;
        background-position: center!important;
        margin-right: 5px !important;
        padding: 5px !important;
      }
      .notification-badge {
        position: absolute;
        top: -3px;
        right: -7px;
        background-color: red; 
        color: white; 
        font-size: 11px; 
        width: 16px; 
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center; 
        justify-content: center; 
        z-index: 9999; 
      }

    `
    document.head.appendChild(style)

    let script = document.createElement("script")
    script.src =
      "https://bots.kore.ai/api/platform/websdkjs/dc9b82ac0c8c4abf9001c1888e11fca10c78b1dcf7ce47a19a40f18500c7ca21st16"
    script.onload = function () {
      if (typeof window.KoreSDK !== "undefined") {
        window.KoreSDK.show(window.KoreSDK.chatConfig)
        document.querySelector(".minimized-title").textContent =
          "¡Chateá con nosotros!"
        minimizedButton = document.querySelector(".minimized")
        observeBotMessages()
      } else {
        console.error("KoreSDK is not defined")
      }
    }
    document.body.appendChild(script)
    function observeBotMessages() {
      chatContainer = document.querySelector(".kore-chat-window") // Ajusta si el selector no coincide
      if (!chatContainer) {
        console.error("Contenedor del chat no encontrado.");
        return;
      }

      const observer = new MutationObserver(handleMutations);
  
      observer.observe(chatContainer, { childList: true, subtree: true });

      setupNotificationBadgeListeners(chatContainer);
    }


    return () => {
      document.head.removeChild(style)
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className={`layout ${classActive}`}>
      <AppProvider>
        {scripts}
        <Helmet>
          <script
            type="text/javascript"
            src={`${process.env.GATSBY_ECOMMERCE_WIDGET_HOST}/ecommerce-widget.umd.cjs`}
            defer
          ></script>
          <link
            rel="stylesheet"
            href={`${process.env.GATSBY_ECOMMERCE_WIDGET_HOST}/style.css`}
          />
          {/* <meta name="facebook-domain-verification" content="s8xkxfcaawge2gog3l8ty7u6bu4iq1" /> */}
          <meta name="facebook-domain-verification" content={codeFaceMeta} />
          {/* <link rel="preconnect" href="https://instagram.com" /> */}
          <link rel="canonical" href="https://21.edu.ar" />
          <link rel="preconnect" href="https://img.youtube.com" />
          <link rel="preconnect" href="https://www.googletagmanager.com" />
          <link
            rel="preconnect"
            href="https://hubspot-public-content.s3.sa-east-1.amazonaws.com"
          />
          {/* <link rel="preconnect" href="https://platform.twitter.com" /> */}
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          active={isActive}
          handleScroll={handleScroll}
          handleLocation={handleLocation}
        />
        <main className="layout__children">{children}</main>
        <BackToTop whatsapp={whatsapp} />
        <Footer />
      </AppProvider>
    </div>
  )
}

export default Layout
